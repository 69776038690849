// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}

.apexcharts-tooltip {
    background: #f3f3f3;
    color: rgb(255, 255, 255);
    background-color: #fff;
}

.mystickyColumn {
    position: sticky;
    left: 0;
    background: #fff;
    border-right-style: solid;
    border-right-color: #e0e0e0;
}
.mystickyColumnHeader {
    position: sticky;
    left: 0;
    z-index: 1;
    // background: #fff;
    border-right-style: solid;
    border-right-color: #e0e0e0;
}
.mystickyColumnRight {
    position: sticky;
    right: 0;
    background: #fff;
    border-left-style: solid;
    border-left-color: #e0e0e0;
}
.textWidthAutoAdjust {
	max-width: 100% !important;
	width: 100%!important;
}
.optionInputWidthAutoAdjust {
	max-width: 100% !important;
	width: 100%!important;
    height: 10px!important;
}
.textAreaWidthAutoAdjust {
    min-height: 60px;
	max-width: 100% !important;
	width: 100%!important;
}
.textWidthAutoAdjustStackNoPad {
	max-width: 100% !important;
	width: 100%!important;
    padding: 0 !important;
}
.textWidthWedAutoAdjust {
    width: -webkit-fill-available !important;
    max-width: 100% !important;
}
.textSinlgeSearchAutoAdjust{
    max-width: 50% !important;
    width: 50% !important;
}
.marginRight5 {
    margin-right: 5px;
}
.tableNoWrap {
    white-space: nowrap;
    padding-right: 20px !important;
    z-index: 0;
}
.statCreated{
    margin-left: 10px;
	border-radius: 10px;
	border: 0.1px;
	background: #535151;
	padding: 5px;
	font-weight: bold;
	/* width: fit-content; */
	color: #003cff;
	text-align: center;
}
.statProcessing{
    margin-left: 10px;
	border-radius: 10px;
	border: 0.1px;
	background: #FBB500;
	padding: 5px;
	font-weight: bold;
	/* width: fit-content; */
	color: #b8b800;
	text-align: center;
}
.statPendingApproval{
    margin-left: 10px;
	border-radius: 10px;
	border: 0.1px;
	background: #398ec0;
	padding: 5px;
	font-weight: bold;
	/* width: fit-content; */
	color: #ffffff;
	text-align: center;
}
.statPending{
    margin-left: 10px;
	border-radius: 10px;
	border: 0.1px;
	background: #535151;
	padding: 5px;
	font-weight: bold;
	/* width: fit-content; */
	color: #ffffff;
	text-align: center;
}
.statFailed{
    margin-left: 10px;
	border-radius: 10px;
	border: 0.1px;
	background: #b84848; //6B0101
	padding: 5px;
	font-weight: bold;
	/* width: fit-content; */
	color: #ffffff;
	text-align: center;
}
.statCancelled{
    margin-left: 10px;
	border-radius: 10px;
	border: 0.1px;
	background: #535151;
	padding: 5px;
	font-weight: bold;
	/* width: fit-content; */
	color: #ffffff;
	text-align: center;
}
.statSuccess{
    margin-left: 10px;
	border-radius: 10px;
	border: 0.1px;
	background: #41AD49;
	padding: 5px;
	font-weight: bold;
	/* width: fit-content; */
	color: #ffffff;
	text-align: center;
}
.statExpired{
    margin-left: 10px;
	border-radius: 10px;
	border: 0.1px;
	background: #535151;
	padding: 5px;
	font-weight: bold;
	/* width: fit-content; */
	color: #993d00;
	text-align: center;
}
.statRefund{
    margin-left: 10px;
	border-radius: 10px;
	border: 0.1px;
	background: #535151;
	padding: 5px;
	font-weight: bold;
	/* width: fit-content; */
	color: #016fff;
	text-align: center;
}

.hidden{
    display:none;
}

@media (max-width: 767px) {
    .textWidthAutoAdjust{
		max-width: 100% !important;
		width: 100% !important;
	}
    .textWidthAutoAdjustStackNoPad {
        max-width: 100% !important;
        width: 100%!important;
        padding: 0 !important;
    }
    .textWidthWedAutoAdjust {
        width: 100% !important;
        max-width: 100% !important;
    }
}

.border-red {
    width: 100% !important;
    max-width: 100% !important;
    border-radius: 16px;
    border: solid;
    border-color: rgb(218, 71, 71) !important;
}

.required-fields {
    font-size: 12px  !important;
    color: rgb(202, 47, 47);
    margin-top: -10px;
}

.MuiLoadingButton-root {
    background-color: #676767;
    color: #fff;
    border: 1px solid rgba(255,255,255,.5);
    font-size: 12px;
    &:hover {
        color: #676767 !important;
    }
}

.MuiLoadingButton-root .MuiCircularProgress-circle {
    stroke: white !important;
    color: #fff !important;
  }

.layer-layout {
    display: flex;
}

.layer-layout .column {
    flex: 1;
}

.legend-label {
    float: left;
    display: flex;
    text-align: left;
    min-width: 200px;
}