// paper & background
$paper: #ffffff;

// primary
$primaryLight: #fdf7e3; // old fdf7e3
$primaryMain: #f3d421;
$primaryDark: #FFD85F; //old e5b71e
$primary200: #f9e090;
$primary800: #dab655;
$primaryBackLinear: linear-gradient(to top left, #FCCB33, #FFD85F);
// linear-gradient(90deg, #FFD238 0%, rgba(255, 210, 56, 0.5625) 101.49%, rgba(255, 210, 56, 0) 101.5%);

// secondary
$secondaryLight: #ede7f6;
$secondaryMain: #673ab7;
$secondaryDark: #5e35b1;
$secondary200: #b39ddb;
$secondary800: #4527a0;

// tertiary
$tertiaryLight: #DADADA;
$tertiaryMain: #7A7A7A;
$tertiaryDark: #616161;
$tertiary200: #B9B9B9;
$tertiary800: #484848;

// success Colors
$successLight: #b9f6ca;
$success200: #69f0ae;
$successMain: #00e676;
$successDark: #00c853;

// error
$errorLight: #ef9a9a;
$errorMain: #f44336;
$errorDark: #c62828;

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #d84315;

// warning
$warningLight: #fff8e1;
$warningMain: #ffe57f;
$warningDark: #ffc107;
$warningSuperDark: rgb(236, 192, 71);

//white
$white1: #fff;

// grey
$grey50: #fafafa;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey500: #9e9e9e;
$grey600: #757575;
$grey700: #616161;
$grey900: #212121;

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #1a223f; // level 3
$darkPaper: #111936; // level 4

// dark 800 & 900
$darkLevel1: #29314f; // level 1
$darkLevel2: #212946; // level 2

// primary dark
$darkPrimaryLight: #fdf7e3;
$darkPrimaryMain: #f3d421;
$darkPrimaryDark: #e5b71e;
$darkPrimary200: #f9e090;
$darkPrimary800: #dab655;

// secondary dark
$darkSecondaryLight: #d1c4e9;
$darkSecondaryMain: #7c4dff;
$darkSecondaryDark: #651fff;
$darkSecondary200: #b39ddb;
$darkSecondary800: #6200ea;

// tertiary dark
$darkTertiaryLight: #e9e0c4;
$darkTertiaryMain: #ffd54d;
$darkTertiaryDark: #ffb11f;
$darkTertiary200: #dbd29d;
$darkTertiary800: #eabf00;

// text variants
$darkTextTitle: #d7dcec;
$darkTextPrimary: #bdc8f0;
$darkTextSecondary: #8492c4;

// ==============================|| JAVASCRIPT ||============================== //

:export {
    // paper & background
    paper: $paper;

    // primary
    primaryLight: $primaryLight;
    primary200: $primary200;
    primaryMain: $primaryMain;
    primaryDark: $primaryDark;
    primary800: $primary800;
    primaryBackLinear: $primaryBackLinear;

    // secondary
    secondaryLight: $secondaryLight;
    secondary200: $secondary200;
    secondaryMain: $secondaryMain;
    secondaryDark: $secondaryDark;
    secondary800: $secondary800;

    // tertiary
    tertiaryLight: $tertiaryLight;
    tertiary200: $tertiary200;
    tertiaryMain: $tertiaryMain;
    tertiaryDark: $tertiaryDark;
    tertiary800: $tertiary800;

    // success
    successLight: $successLight;
    success200: $success200;
    successMain: $successMain;
    successDark: $successDark;

    // error
    errorLight: $errorLight;
    errorMain: $errorMain;
    errorDark: $errorDark;

    // orange
    orangeLight: $orangeLight;
    orangeMain: $orangeMain;
    orangeDark: $orangeDark;

    // warning
    warningLight: $warningLight;
    warningMain: $warningMain;
    warningDark: $warningDark;
    warningSuperDark: $warningSuperDark;

    // grey
    white1: $white1;

    // grey
    grey50: $grey50;
    grey100: $grey100;
    grey200: $grey200;
    grey300: $grey300;
    grey500: $grey500;
    grey600: $grey600;
    grey700: $grey700;
    grey900: $grey900;

    // ==============================|| DARK THEME VARIANTS ||============================== //

    // paper & background
    darkPaper: $darkPaper;
    darkBackground: $darkBackground;

    // dark 800 & 900
    darkLevel1: $darkLevel1;
    darkLevel2: $darkLevel2;

    // text variants
    darkTextTitle: $darkTextTitle;
    darkTextPrimary: $darkTextPrimary;
    darkTextSecondary: $darkTextSecondary;

    // primary dark
    darkPrimaryLight: $darkPrimaryLight;
    darkPrimaryMain: $darkPrimaryMain;
    darkPrimaryDark: $darkPrimaryDark;
    darkPrimary200: $darkPrimary200;
    darkPrimary800: $darkPrimary800;

    // secondary dark
    darkSecondaryLight: $darkSecondaryLight;
    darkSecondaryMain: $darkSecondaryMain;
    darkSecondaryDark: $darkSecondaryDark;
    darkSecondary200: $darkSecondary200;
    darkSecondary800: $darkSecondary800;

    // tertiary dark
    darkTertiaryLight: $darkTertiaryLight;
    darkTertiaryMain: $darkTertiaryMain;
    darkTertiaryDark: $darkTertiaryDark;
    darkTertiary200: $darkTertiary200;
    darkTertiary800: $darkTertiary800;
}
